<script setup lang="ts">
import Vue, { computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseUploadFilesArea from '/~/components/base/upload-files/base-upload-files-area.vue'
import BaseUploadFiles from '/~/components/base/upload-files/base-upload-files.vue'
import {
  usePayrollOrder,
  usePayrollOrderStatusHandler,
} from '/~/composables/batch-order/payroll'

import { useLogger } from '/~/composables/logger'

withDefaults(
  defineProps<{
    extensions?: string[]
  }>(),
  {
    extensions: () => ['aba'],
  }
)

const { isBatchOrderLoading, createPayrollOrder } = usePayrollOrder()
const { payrollOrderStatusHandler } = usePayrollOrderStatusHandler()

const logger = useLogger('batch-order')

const uploadFilesElement = ref(null)
const fileUploadingError = ref('')
const isInitialized = ref(false)

const creatingProgress = computed(() => {
  const loadedPercents = uploadFilesElement.value?.loadedPercents ?? 0

  return loadedPercents
})

function init() {
  try {
    payrollOrderStatusHandler.resetBatchOrder()
    payrollOrderStatusHandler.startCheckingLoop()
  } catch (error) {
    Vue.notify({
      type: 'error',
      text: error.message,
    })
  }

  isInitialized.value = true
}

async function onFilesUploaded(files) {
  const file = files[0]

  try {
    await createPayrollOrder({
      fileUploadId: file.id,
    })

    payrollOrderStatusHandler.startCheckingLoop()
  } catch (error) {
    logger.error(error)

    Vue.notify({
      type: 'error',
      text: 'Something went wrong. Please try again.',
    })
  }
}

function onFilesUploadingError() {
  fileUploadingError.value = 'Upload failed, please try again'
  uploadFilesElement.value?.resetUploadableFiles()
}

function onFilesUploadingStarted() {
  fileUploadingError.value = ''
}

function orderCreationFailed(errorText: string) {
  Vue.notify({
    type: 'error',
    text: errorText,
  })

  uploadFilesElement.value?.resetUploadableFiles()
}

onBeforeMount(() => {
  setTimeout(() => {
    emitter.on('batch-order-creation-failed', orderCreationFailed)
    emitter.on('batch-order-creation-cancelled', orderCreationFailed)
  })
})

onBeforeUnmount(() => {
  emitter.off('batch-order-creation-failed', orderCreationFailed)
  emitter.off('batch-order-creation-cancelled', orderCreationFailed)
})

init()
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? BaseAsidePage : 'div'"
      title="Upload bank file"
      :no-padding="true"
      :back="{ name: 'home' }"
    >
      <div class="px-12 py-8">
        <h1 class="hidden text-2xl sm:block">
          {{ $route.meta?.title || 'Upload bank file' }}
        </h1>
        <p class="mt-2 hidden text-eonx-neutral-600 sm:block">
          Earn points on payroll payments by uploading ABA files from your
          preferred accounting software.
        </p>
        <div
          v-if="payrollOrderStatusHandler.batchOrderLoader.loading"
          class="mt-10 flex flex-col items-center"
        >
          <base-loader size="xl" />
        </div>
        <template v-else>
          <base-upload-files
            ref="uploadFilesElement"
            :is-list-visible="false"
            :is-action-visible="ui.mobile"
            :is-area-visible="true"
            :extensions="extensions"
            driver="S3Driver"
            class="mt-8"
            @files-uploading-error="onFilesUploadingError"
            @files-uploading-started="onFilesUploadingStarted"
            @files-uploaded="onFilesUploaded"
          >
            <template #area="areaProps">
              <base-upload-files-area
                :is-uploading="areaProps.isUploading"
                :loaded-percents="areaProps.loadedPercents"
                @drop="areaProps.onDrop"
                @click="areaProps.showFileDialog"
              >
                <template #content>
                  <div
                    class="flex min-h-full flex-col items-center justify-center text-center"
                  >
                    <div class="mb-4 text-eonx-neutral-800">
                      <base-icon
                        :svg="'heroicons/outline/arrow-up-tray'"
                        :size="50"
                      />
                    </div>
                    <template
                      v-if="areaProps.isUploading || isBatchOrderLoading"
                    >
                      <div class="text-sm">
                        Uploading {{ creatingProgress }}%
                      </div>
                      <div
                        class="mt-4 h-2.5 w-80 overflow-hidden rounded-full bg-eonx-neutral-50"
                      >
                        <div
                          class="h-full bg-primary transition-width duration-500"
                          :style="{
                            width: `${creatingProgress}%`,
                          }"
                        />
                      </div>
                    </template>
                    <div v-else class="text-lg text-eonx-neutral-800">
                      <span class="font-bold">Upload ABA file</span>
                      <span v-if="!ui.mobile">
                        or simply
                        <span class="font-bold">drag and drop</span>
                      </span>
                    </div>
                  </div>
                </template>
                <template #overlay="overlayProps">
                  <div
                    v-if="overlayProps.isDraggingInside"
                    class="absolute left-0 top-0 h-full w-full rounded bg-white opacity-75"
                  />
                  <div v-else />
                </template>
                <template #overlay-content>
                  <div />
                </template>
              </base-upload-files-area>
            </template>

            <template #action>
              <div class="flex flex-col items-center justify-center space-y-6">
                <base-icon
                  svg="heroicons/solid/information-circle"
                  :size="60"
                  class="text-primary"
                />
                <div class="text-center">
                  This feature isn’t available on mobile. Please use a laptop or
                  desktop to access it.
                </div>
              </div>
            </template>
          </base-upload-files>
          <div v-if="fileUploadingError" class="mt-4 flex justify-center">
            <div
              class="flex items-center rounded-md bg-orange-50 px-[30px] py-2.5 text-center text-sm text-orange-700"
            >
              <base-icon
                svg="symbion/warning"
                :size="19"
                class="mr-2.5 text-orange-700"
              />
              {{ fileUploadingError }}
            </div>
          </div>
        </template>
      </div>
    </component>
  </layout-main>
</template>
